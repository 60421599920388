export const quotesArray = [
    { quote: "The only way to do great work is to love what you do.", author: "Steve Jobs" },
    { quote: "I have not failed. I've just found 10,000 ways that won't work.", author: "Thomas Edison" },
    { quote: "Don't watch the clock; do what it does. Keep going.", author: "Sam Levenson" },
    { quote: "The best way to predict the future is to create it.", author: "Peter Drucker" },
    { quote: "Your time is limited, so don't waste it living someone else's life.", author: "Steve Jobs" },
    { quote: "You miss 100% of the shots you don't take.", author: "Wayne Gretzky" },
    { quote: "Act as if what you do makes a difference. It does.", author: "William James" },
    { quote: "The future belongs to those who believe in the beauty of their dreams.", author: "Eleanor Roosevelt" },
    { quote: "The purpose of our lives is to be happy.", author: "Dalai Lama" },
    { quote: "Life is what happens when you're busy making other plans.", author: "John Lennon" },
    { quote: "Get busy living or get busy dying.", author: "Stephen King" },
    { quote: "Believe you can and you're halfway there.", author: "Theodore Roosevelt" },
    { quote: "Success usually comes to those who are too busy to be looking for it.", author: "Henry David Thoreau" },
    { quote: "Don't be afraid to give up the good to go for the great.", author: "John D. Rockefeller" },
    { quote: "I find that the harder I work, the more luck I seem to have.", author: "Thomas Jefferson" },
    { quote: "Success is not in what you have, but who you are.", author: "Bo Bennett" },
    { quote: "The only place where success comes before work is in the dictionary.", author: "Vidal Sassoon" },
    { quote: "Don't wait for opportunity. Create it.", author: "George Bernard Shaw" },
    { quote: "The road to success and the road to failure are almost exactly the same.", author: "Colin R. Davis" },
    { quote: "Success is walking from failure to failure with no loss of enthusiasm.", author: "Winston Churchill" },
    { quote: "The successful warrior is the average man, with laser-like focus.", author: "Bruce Lee" },
    { quote: "If you really look closely, most overnight successes took a long time.", author: "Steve Jobs" },
    { quote: "The way to get started is to quit talking and begin doing.", author: "Walt Disney" },
    { quote: "Don't let yesterday take up too much of today.", author: "Will Rogers" },
    { quote: "It's not whether you get knocked down, it's whether you get up.", author: "Vince Lombardi" },
    { quote: "Failure will never overtake me if my determination to succeed is strong enough.", author: "Og Mandino" },
    { quote: "We may encounter many defeats but we must not be defeated.", author: "Maya Angelou" },
    { quote: "Knowing is not enough; we must apply. Wishing is not enough; we must do.", author: "Johann Wolfgang Von Goethe" },
    { quote: "Imagine your life is perfect in every respect; what would it look like?", author: "Brian Tracy" },
    { quote: "We generate fears while we sit. We overcome them by action.", author: "Dr. Henry Link" },
    { quote: "Whether you think you can or think you can’t, you’re right.", author: "Henry Ford" },
    { quote: "The man who has confidence in himself gains the confidence of others.", author: "Hasidic Proverb" },
    { quote: "The only way to achieve the impossible is to believe it is possible.", author: "Charles Kingsleigh" },
    { quote: "Do what you can with all you have, wherever you are.", author: "Theodore Roosevelt" },
    { quote: "You are never too old to set another goal or to dream a new dream.", author: "C.S. Lewis" },
    { quote: "To see what is right and not do it is a lack of courage.", author: "Confucius" }
];
